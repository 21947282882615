<template>
  <v-tooltip class="px-2" color="rgba(0, 0, 0, 0.85)" top>
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <slot name="content"> </slot>
      </div>
    </template>
    <div class="body-regular">
      <div class="pa-0">
        <div v-if="isPackageGroup(item)" t-data="tooltip-group-content">
          {{ item.package_name_th }}
          <ul style="line-height: 24px">
            <li>
              {{ item.sub_package_name_th }}
            </li>
          </ul>
          {{ item.package_name_en }}
          <ul style="line-height: 24px">
            <li>
              {{ item.sub_package_name_en }}
            </li>
          </ul>
        </div>
        <div t-data="tooltip-single-content" v-else>
          <div>
            {{ item.package_name_th }}
          </div>
          <div>
            {{ item.package_name_en }}
          </div>
        </div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipTitle',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    isPackageGroup(lineItem) {
      return (
        'sub_package_name_en' in lineItem &&
        lineItem.sub_package_name_en !== null
      )
    },
  }
}
</script>
<style scoped>
.v-tooltip__content {
  padding: 8px 16px !important;
}
</style>
