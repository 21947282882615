<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>

      <v-list-item class="mt-2 ml-5 mr-5 fixWidth d-block" t-data="order-date-filter">
        <p class="mb-0 filterContentTitle sub-body-bold">Order Date</p>
        <custom-date-picker
          placeholder=""
          v-model="orderDateSelected"
          :rule="[() => true]"
          outlined
          required
        ></custom-date-picker>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '@/components/MultipleSelected'
import FilterButton from '@/components/FilterButton'
import DatePickerRange from '@/components/DatePickerRange'
import {convertSelectedData} from '@/helper/helper'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    orderDateSelected: [],
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
    appliedOrderDate: [],
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected,
    'custom-date-picker': DatePickerRange
  },
  computed: {
    isClearFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      const changedOrderDate = !_.isEqual(this.orderDateSelected, [])
      if (this.hasOneOrg) return changedOrderDate
      return changedBranch || changedOrderDate
    },
    isChangeFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, this.appliedBranches)
      const changedOrderDate = !_.isEqual(this.orderDateSelected, this.appliedOrderDate)
      if (this.hasOneOrg) return changedOrderDate
      return changedBranch || changedOrderDate
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    ...mapState('digitalProduct', ['orgList'])
  },
  methods: {
    sumFiltered() {
      const sumBranch = this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumOrderDate = this.orderDateSelected.length > 0 ? 1 : 0
      return sumBranch + sumOrderDate
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBranches = this.orgList.selected
      this.appliedOrderDate = this.orderDateSelected
      this.setFilterOrderDate(this.orderDateSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.orderDateSelected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.orderDateSelected = this.appliedOrderDate
        this.setFilterOrderDate(this.orderDateSelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('digitalProduct', ['fetch', 'setFilterOrderDate'])
  }
}
</script>
