<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="455">
      <v-card>
        <v-card-title class="headline text-truncate" t-data="headline">
          <h4>
            <span class="headerTextColor--text">Edit Status</span>
          </h4>
        </v-card-title>
        <v-card-text class="mt-0 detail-container">
          <div class="mb-5">
            <v-row class="mb-3">
            <v-col
              t-data="info-label"
              class="no-top-bottom-space information-label body-regular"
              cols="6"
            >
              Order No.
            </v-col>
            <v-col
              t-data="info-label"
              class="no-top-bottom-space information-label body-regular"
              cols="6"
            >
              Item Name
            </v-col>
          </v-row>
          <v-row>
            <v-col
              t-data="info-value"
              class="no-top-bottom-space d-inline-flex information-text body-bold"
              cols="6"
            >
              {{ item.order_number }}
            </v-col>
            <v-col
              t-data="info-value"
              class="no-top-bottom-space information-text body-bold"
              cols="6"
            >
              <span>
                {{ item.package_name_en }}
              </span>
              <br>
              <span class="body-regular">
                {{ item.sub_package_name_en }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-3 mt-5">
            <v-col
              t-data="info-label"
              class="no-top-bottom-space information-label body-regular"
              cols="6"
            >
              Current Status
            </v-col>
            <v-col
              t-data="info-label"
              class="no-top-bottom-space information-label body-regular"
              cols="6"
            >
              Validity Period
            </v-col>
          </v-row>
          <v-row>
            <v-col
              t-data="info-value"
              class="no-top-bottom-space d-inline-flex information-text"
              cols="6"
            >
               <voucher-status-chip :status="item.status"></voucher-status-chip>
            </v-col>
            <v-col
              t-data="info-value"
              class="no-top-bottom-space d-inline-flex information-text body-bold"
              cols="6"
            >
              {{ dateFmt(item.validity_start_date) }} - {{ dateFmt(item.validity_end_date) }}
            </v-col>
          </v-row>
          </div>
          <v-divider class="pa-0"></v-divider>
          <v-form ref="form" class="edit-container edit-status-form mt-4" lazy-validation>
            <p class="edit-field-label sub-body-bold" t-data="form-label">Edit Reason</p>
            <v-radio-group
              t-data="form-value"
              class="mt-0"
              v-model="form.selectedReason"
              :rules="reasonRules"
            >
              <template v-for="(reason, index) in reasons">
                <v-radio
                  t-data="radio-btn"
                  :key="index"
                  :label="reason.label"
                  :value="reason.value"
                  color="secondary"
                ></v-radio>
              </template>
            </v-radio-group>
            <v-row>
              <v-col :cols="columnNum">
                <p class="edit-field-label sub-body-bold" t-data="form-label">New Status</p>
                <v-text-field
                  outlined
                  dense
                  class="subtitle-2 can-disable-selector"
                  t-data="form-value"
                  :value="newStatusText"
                  placeholder=""
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="showValidityDate">
                <p class="edit-field-label sub-body-bold" t-data="form-label">New Validity End Date</p>
                <custom-date-picker
                  t-data="form-value"
                  placeholder="Select End Date"
                  v-model="form.validityEndDate"
                  :isDisable="true"
                  outlined
                ></custom-date-picker>
              </v-col>
            </v-row>

            <v-alert
              v-if="showValidityDate"
              class="alert-date-container info800Color--text sub-body-regular"
              color="info200Color"
              icon="mdi-information"
              dense
            > New validity end date will set to current date.</v-alert>

            <p class="edit-field-label sub-body-bold" t-data="form-label">Please enter edit note</p>
            <v-textarea
              required
              outlined
              dense
              class="subtitle-2 edit-note-input"
              t-data="form-value"
              v-model="form.reasonNote"
              placeholder="Enter note here ..."
              rows="2"
              row-height="20"
              :rules="reasonNoteRules"
            ></v-textarea>
            <p class="edit-field-label sub-body-bold" t-data="form-label">Editor</p>
            <v-text-field
              outlined
              dense
              class="subtitle-2 can-disable-selector"
              t-data="form-value"
              :value="user.name"
              placeholder=""
              disabled
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="cancel-button button-bold" t-data="dialog-btn" color="info600Color" text @click="close">
            Cancel
          </v-btn>
          <v-btn
            class="confirm-button button-bold"
            t-data="dialog-btn"
            color="info600Color"
            text
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VoucherStatusChip from '@/components/digital-product/VoucherStatusChip'
import {convertDateBirthDate} from '@/helper/helper'
import {digitalProductStatus, getNewStatus, getReasons, reasonEnum} from '@/constants/digital-product'
import {mapState} from 'vuex'
import CustomDatePicker from '@/components/DatePickerWithMin'
import moment from 'moment'

export default {
  name: 'EditStatusDialog',
  props: {
    show: {type: Boolean},
    item: {type: Object},
  },
  data: () => ({
    reasons: [],
    showValidityDate: false,
    form: {
      selectedReason: undefined,
      newStatus: '',
      reasonNote: '',
      validityEndDate: ''
    },
    reasonNoteRules: [v => !!v || "Please select edit note."]
  }),
  components: {
    CustomDatePicker,
    VoucherStatusChip,
  },
  watch: {
    show() {
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
      this.resetData()
      this.reasons = getReasons(this.item.status)
    },
    'item.status' () {
      this.reasons = getReasons(this.item.status)
    },
    'form.selectedReason'() {
      this.showValidityDate = false
      this.form.newStatus = getNewStatus(this.item.status, this.form.selectedReason)
      if (this.form.selectedReason === reasonEnum.RENEW_USED_ONLINE) {
        this.showValidityDate = true
        this.form.validityEndDate = moment(new Date()).toISOString().substr(0, 10)
      }
    }
  },
  computed: {
    reasonRules() {
      return [
        this.form.selectedReason !== undefined || "Please select edit reason."
      ]
    },
    columnNum() {
      return this.showValidityDate ? 6 : 12
    },
    newStatusText() {
      return digitalProductStatus[this.form.newStatus]
    },
    ...mapState('auth', ['user'])
  },
  methods: {
    resetData() {
      this.reasons = []
      this.showValidityDate = false
      this.form = {
        selectedReason: undefined,
        newStatus: '',
        reasonNote: '',
        validityEndDate: ''
      }
    },
    dateFmt: date => convertDateBirthDate(date),
    close() {
      this.$emit('close')
    },
    validate() {
      return !!this.$refs.form.validate();
    },
    save() {
      if (this.validate()) {
        this.$emit('save', {...this.form, ...{id: this.item.id}})
      }
    }
  }
}
</script>

<style lang="scss">
.alert-date-container > .v-alert__wrapper > .theme--light.v-icon {
  color: var(--info-500-color);
  font-size: 1.25rem;
  margin-right: 10px;
}

.edit-status-form {
  .v-text-field__details {
    display: none;
  }
  .edit-note-input {
    .v-text-field__details {
      display: inline;
      margin-bottom: 2px;
    }
  }
}
</style>