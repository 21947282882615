export const digitalProductStatusEnum = {
  NEW: 'NEW',
  REDEEMED: 'REDEEMED',
  USED: 'USED',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED'
}

export const reasonEnum = {
  TRANSFER_MOBILE: 'TRANSFER_MOBILE',
  ACCOUNT_DELETED: 'ACCOUNT_DELETED',
  CONTINUE: 'CONTINUE',
  RENEW_USED_ONLINE: 'RENEW_USED_ONLINE',
  RENEW_USED_OFFLINE: 'RENEW_USED_OFFLINE'
}

export const digitalProductStatus = {
  [digitalProductStatusEnum.NEW]: 'Available',
  [digitalProductStatusEnum.REDEEMED]: 'Redeemed',
  [digitalProductStatusEnum.USED]: 'Used',
  [digitalProductStatusEnum.EXPIRED]: 'Expired',
  [digitalProductStatusEnum.REFUNDED]: 'Refunded'
}

export const reasonText = {
  [reasonEnum.TRANSFER_MOBILE]: 'Transfer/No mobile phone',
  [reasonEnum.ACCOUNT_DELETED]: 'Account deleted',
  [reasonEnum.CONTINUE]: 'Continue',
  [reasonEnum.RENEW_USED_ONLINE]: 'Renew to use online',
  [reasonEnum.RENEW_USED_OFFLINE]: 'Renew to use offline'
}

export const enableEdit = status => {
  return [
    digitalProductStatusEnum.NEW,
    digitalProductStatusEnum.REDEEMED,
    digitalProductStatusEnum.EXPIRED
  ].includes(status)
}

export const getReasons = status => {
  if (status === digitalProductStatusEnum.NEW) {
    return [
      {
        label: reasonText[reasonEnum.TRANSFER_MOBILE],
        value: reasonEnum.TRANSFER_MOBILE
      },
      {
        label: reasonText[reasonEnum.ACCOUNT_DELETED],
        value: reasonEnum.ACCOUNT_DELETED
      }
    ]
  } else if (status === digitalProductStatusEnum.REDEEMED) {
    return [
      {label: reasonText[reasonEnum.CONTINUE], value: reasonEnum.CONTINUE}
    ]
  } else if (status === digitalProductStatusEnum.EXPIRED) {
    return [
      {
        label: reasonText[reasonEnum.RENEW_USED_ONLINE],
        value: reasonEnum.RENEW_USED_ONLINE
      },
      {
        label: reasonText[reasonEnum.RENEW_USED_OFFLINE],
        value: reasonEnum.RENEW_USED_OFFLINE
      }
    ]
  } else {
    return []
  }
}

export const getNewStatus = (status, reason) => {
  if (status === digitalProductStatusEnum.NEW) {
    return digitalProductStatusEnum.USED
  } else if (status === digitalProductStatusEnum.REDEEMED) {
    return digitalProductStatusEnum.USED
  } else if (status === digitalProductStatusEnum.EXPIRED) {
    if (reason === reasonEnum.RENEW_USED_ONLINE) {
      return digitalProductStatusEnum.NEW
    } else if (reason === reasonEnum.RENEW_USED_OFFLINE) {
      return digitalProductStatusEnum.USED
    } else {
      return null
    }
  } else {
    return null
  }
}
