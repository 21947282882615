<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoadingExport" />
    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Digital Product</h3>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="8" t-data="search-panel">
            <search
              :search="search"
              label="Search by Order No. / Invoice No., Serial Code, Item Name, Buyer Name or Mobile No."
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col cols="12" sm="2"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center"
            t-data="filter-panel"
          >
            <export-csv-button
              class="mr-4"
              :filename="'Product report'"
              :data="csvData"
              :total="total"
              :is-fail="total > limitExport"
              :fetchExportData="handleExport"
            ></export-csv-button>
            <filter-dropdown @apply="handleApplyFilter()"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-model="selectedStatus"
        @change="handleChangeStatus"
        class="status-tab body-regular"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
      >
        <v-tab
          t-data="tab-panel"
          v-for="status in listStatus"
          :key="status.value"
        >
          {{ status.name }}
        </v-tab>
      </v-tabs>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="digitalProductList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table digital-product-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.action="{item}">
            <div>
              <v-icon
                t-data="edit-btn"
                :disabled="disabledEdit(item.status)"
                class="px-2 action-icon"
                @click="edit(item)"
                >mdi-pencil-outline</v-icon
              >
              <v-tooltip
                v-if="showComment(item)"
                t-data="note-btn"
                color="black"
                max-width="300"
                top
              >
                <template v-slot:activator="{on, attrs}">
                  <v-icon class="px-2 action-icon" v-bind="attrs" v-on="on"
                    >mdi-comment-processing-outline</v-icon
                  >
                </template>
                <span class="break-text">
                  <div v-if="item.edit_reason">
                    <b>Edit Reason</b><br />
                    {{ getReasonText(item.edit_reason) }}
                  </div>
                  <br v-if="item.reason_note && item.edit_reason" />
                  <div v-if="item.reason_note">
                    <b>Note</b><br />
                    {{ item.reason_note }}
                  </div>
                </span>
              </v-tooltip>
              <v-icon v-else class="px-2 action-icon transparent-icon"></v-icon>
            </div>
          </template>
          <template v-slot:item.created_date="{item}">
            {{ dateAndTimeFmt(item.created_date) }}</template
          >
          <template v-slot:item.org_codename="{item}">{{
            item.org_codename.toUpperCase()
          }}</template>
          <template v-slot:item.package_name_en="{item}">
            <tooltip-title :item="item">
              <template v-slot:content>
                <v-list-item-content
                  class="item-content"
                  style="line-height: 24px"
                >
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 190px"
                    v-if="isPackageGroup(item)"
                  >
                    <v-list-item-title style="line-height: 24px">
                      {{ item.package_name_th }}

                      <div style="height: 30px">
                        <div
                          class="d-inline-block text-truncate"
                          style="width: 190px"
                        >
                          &bull;
                          {{ item.sub_package_name_th }}
                        </div>
                      </div>
                    </v-list-item-title>
                    <v-list-item-title style="line-height: 24px">
                      {{ item.package_name_en }}

                      <div style="height: 30px">
                        <div
                          class="d-inline-block text-truncate"
                          style="width: 190px"
                        >
                          &bull;
                          {{ item.sub_package_name_en }}
                        </div>
                      </div>
                    </v-list-item-title>
                  </div>
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 190px"
                    v-else
                  >
                    <v-list-item-title style="line-height: 24px">
                      {{ item.package_name_th }}
                    </v-list-item-title>
                    <v-list-item-title style="line-height: 24px">
                      {{ item.package_name_en }}
                    </v-list-item-title>
                  </div>
                </v-list-item-content>
              </template>
            </tooltip-title>
          </template>
          <template v-slot:item.price="{item}">
            <div v-if="item.price">฿ {{ convertNumToComma(item.price) }}</div>
          </template>
          <template v-slot:item.validity_end_date="{item}">
            {{ dateFmt(item.validity_end_date) }}</template
          >
          <template v-slot:item.update_date="{item}">
            {{ dateAndTimeFmt(item.update_date) }}</template
          >
          <template v-slot:item.status="{item}">
            <voucher-status-chip :status="item.status"></voucher-status-chip>
          </template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ total }}</b> Search results
        </div>
      </div>
    </v-card>
    <edit-status-dialog
      :show="showDialog"
      :item="editedItem"
      @close="showDialog = false"
      @save="handleEditStatus"
    ></edit-status-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {
  convertDate,
  convertDateBirthDate,
  convertIntegerToCommaNumber,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent
} from '@/helper/helper'
import {limitExport, paymentMethod} from '@/constants/order'
import AlertError from '@/components/AlertError'
import EditStatusDialog from '@/components/digital-product/EditStatusDialog'
import ExportCsvButton from '@/components/ExportCsvButton'
import FilterDropdown from './FilterDropdown'
import PopupLoading from '@/components/PopupLoading'
import Search from '@/components/Search'
import VoucherStatusChip from '@/components/digital-product/VoucherStatusChip'
import SnackBar from '@/components/SnackBar'
import TooltipTitle from '@/components/digital-product/TooltipTitle'
import {
  digitalProductStatus,
  enableEdit,
  reasonText
} from '@/constants/digital-product'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    'v-alert-error': AlertError,
    ExportCsvButton,
    Search,
    FilterDropdown,
    VoucherStatusChip,
    EditStatusDialog,
    SnackBar,
    TooltipTitle
  },
  name: 'list',
  data() {
    return {
      pageName: 'product',
      heightOptions: {
        header: 360,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      limitExport,
      csvData: '',
      showDialog: false,
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'edited',
      isError: false,
      editedItem: {},
      linkRoute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Digital Product',
          disabled: true,
          href: '/'
        }
      ],
      search: '',
      options: {},
      page: 1,
      headers: [
        {
          text: 'Action',
          align: 'center',
          value: 'action',
          sortable: false,
          width: 128
        },
        {
          text: 'Order No.',
          align: 'left',
          value: 'order_number',
          sortable: true,
          width: 128
        },
        {
          text: 'Invoice No.',
          align: 'left',
          value: 'order.payment_id',
          sortable: true,
          width: 128
        },
        {
          text: 'Buyer Name',
          align: 'left',
          value: 'user_full_name',
          sortable: true,
          width: 182
        },
        {
          text: 'Mobile No.',
          align: 'left',
          value: 'user_phone_number',
          sortable: true,
          width: 140
        },
        {
          text: 'Package Status',
          align: 'left',
          value: 'status',
          sortable: true,
          width: 160
        },
        {
          text: 'Item Name',
          align: 'left',
          value: 'package_name_en',
          sortable: true,
          width: 190
        },
        {
          text: 'Order Date/Time',
          align: 'left',
          value: 'created_date',
          sortable: true,
          width: 180
        },
        {
          text: 'Centrix Code',
          align: 'left',
          value: 'centrix_code',
          sortable: true,
          width: 140
        },
        {
          text: 'Serial Code',
          align: 'left',
          value: 'redemption_code',
          sortable: true,
          width: 142
        },
        {
          text: 'Sale Price (Baht)',
          align: 'right',
          value: 'price',
          sortable: true,
          width: 165
        },
        {
          text: 'Department',
          align: 'left',
          value: 'department_name_en',
          sortable: true,
          width: 150
        },
        {
          text: 'Expiry Date',
          align: 'left',
          value: 'validity_end_date',
          sortable: true,
          width: 140
        },
        {
          text: 'Updated Date/Time',
          align: 'left',
          value: 'update_date',
          sortable: true,
          width: 190
        },
        {
          text: 'ID Card No. / Passport No.',
          align: 'left',
          value: 'user_id_card',
          sortable: true,
          width: 160
        },
        {
          text: 'Branch',
          align: 'left',
          value: 'org_codename',
          sortable: true,
          width: 110
        }
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100],
      selectedStatus: 0,
      paymentMethodItems: paymentMethod
    }
  },
  computed: {
    listStatus() {
      return [
        {
          name: 'All',
          value: ''
        },
        ...Object.entries(digitalProductStatus).map(s => ({
          value: s[0],
          name: s[1]
        }))
      ]
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('digitalProduct', [
      'filterStatus',
      'filterOrderDate',
      'isLoading',
      'isLoadingExport',
      'digitalProductCsv',
      'digitalProductList',
      'total'
    ])
  },
  created() {
    this.setListOrgInfo()
    this.fetch()
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    isPackageGroup(lineItem) {
      return (
        'sub_package_name_en' in lineItem &&
        lineItem.sub_package_name_en !== null
      )
    },
    getReasonText(reason) {
      return reasonText[reason]
    },
    showComment(item) {
      return item.reason_note || item.edit_reason
    },
    disabledEdit(status) {
      return !enableEdit(status)
    },
    getTitleByCode(productContent, code) {
      const products = productContent.filter(p => p.code === code)
      return products.length > 0 ? products[0].title : ''
    },
    generateCsvText() {
      const genDateRange = dates =>
        dates.length > 0
          ? dates.map(d => convertDateBirthDate(d)).join(' to ')
          : '- to -'
      const orderDataText = genDateRange(this.filterOrderDate)
      const header = `รายงานสรุปการใช้งานแพ็คเกจ\nOrder date ${orderDataText}\n\n`
      return `${header}${this.digitalProductCsv}`
    },
    async handleExport() {
      await this.fetchExportCsv()
      this.csvData = this.generateCsvText()
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.setTextSearch(this.search.trim())
        this.fetch()
      })
    },
    clearSearch() {
      this.search = ''
      this.options.page = 1
      this.page = 1
      this.fetch(this.page)
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    dateFmt: date => convertDateBirthDate(date),
    dateAndTimeFmt: date => convertDate(date),
    handleChangeStatus(value) {
      const selectedValue = this.listStatus[value].value
      this.setFilterStatus(selectedValue)
      this.fetch()
    },
    async handleEditStatus(value) {
      const isSuccess = await this.editPackageStatus(value)
      this.showDialog = false
      if (isSuccess) {
        await this.fetch()
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    closeAlert() {
      this.isError = false
    },
    edit(item) {
      this.showDialog = true
      this.editedItem = item
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    ...mapActions('digitalProduct', [
      'editPackageStatus',
      'fetch',
      'fetchExportCsv',
      'resetList',
      'setFieldSort',
      'setFilterStatus',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        let customSortBy

        if (sortBy[0] === 'package_name_en') {
          customSortBy = 'package_name'
        } else if (sortBy[0] === 'department_name_en') {
          customSortBy = 'department_name'
        } else if (sortBy[0] === 'order.payment_id') {
          customSortBy = 'payment_id'
        } else {
          customSortBy = sortBy[0]
        }

        this.setPage(page)
        this.setFieldSort(customSortBy)
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1
      }
    },
    selectedStatus: {
      handler() {
        this.options.page = 1
      }
    }
  }
}
</script>

<style lang="scss">
.digital-product-table.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  height: 56px;
}

.digital-product-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr {
  height: 76px;
}

.digital-product-table td {
  max-height: 129px !important;
  min-height: 129px !important;
  height: 129px !important;
  vertical-align: top;
  padding-top: 12px !important;
  .v-list-item__content {
    padding-top: 0;
  }
}
</style>